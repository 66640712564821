// Import all the tools needed to customize the theme and extract parts of it
@use '@angular/material' as mat;
@import 'node_modules/@angular/material/index';

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin customer-contract-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $warn: map-get($theme, warn);

  // Use mat-color to extract individual colors from a palette as necessary.
  .invalid {
    color: mat.get-color-from-palette($warn)
  }
}
