/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@taures/angular-commons/taures.theme';
@import 'app/components/task-wizard-container/task-wizard-container.component-theme';
@import 'app/components/data-comparison/customer-contract/customer-contract.component-theme';
@import 'spinner';
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/material-icons";
@import "@fontsource/material-icons-outlined";
@import "primeflex/primeflex";

@include customer-contract-theme($taures-theme);
@include task-wizard-theme($taures-theme);

@include mat.form-field-density(-1);
$primary-background: #E5E9F1;

:root {
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.60);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.60);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.60);
  --mdc-filled-text-field-container-color: #FAFAFA;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.87);
}

html, body {
  font-size: 14px;
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.taures-primary-blue {
  background-color: #003572 !important;
  color: white !important;
}

.taures-secondary-blue {
  background-color: #4C719C !important;
  color: white !important;
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  width: 24px;
  height: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  @extend .material-icons;
}


.mdc-button:not(.mat-calendar-period-button) {
  letter-spacing: 0;
}

.mat-mdc-select, .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input, .mat-mdc-form-field, .mat-mdc-floating-label, .mat-mdc-option {
  font-size: 14px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: black !important;
  font-size: 14px !important;
}

.mdc-checkbox__checkmark {
  color: white !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 !important;
}

.input-line-break .mat-mdc-select-min-line {
  white-space: normal !important;
}

.mat-mdc-dialog-title {
  font-weight: 400 !important;
}

.expandable-overlay-panel {
  min-width: max-content !important;
}

/**
Size should be equal to input
 */
.mat-select-trigger {
  font-size: 14px !important;
}

.mat-form-field-infix {
  width: 100% !important;
}

.mat-select-value-text {
  white-space: normal !important;
}

.mat-tooltip {
  font-size: 10pt !important;
}

.taures-header-invalid {
  background-color: mat.get-color-from-palette(map-get($taures-theme, warn));
  color: white;
}

.taures-light-snackbar {
  --mdc-snackbar-container-color: #4C719C;

  span {
    text-align: center;
  }
}

.mat-dialog-actions {
  margin-bottom: -6px !important;
}

.pdf-preview-dialog .mat-mdc-dialog-content {
  max-height: 90vh !important;
}

.border-4px {
  border-radius: 4px;
}

.border-box-elem {
  box-sizing: border-box;
}

//Tab Overwrites
.mat-mdc-tab {
  --mat-tab-header-label-text-tracking: 1.25px;
}

//Label text-field Overwrites
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: #dd2c00 !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  --mat-form-field-focus-state-layer-opacity: 0.12;
}

//Radio Buttons Overwrites
.radio-button-size .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 32px;
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

mat-radio-button .mdc-form-field .mdc-label {
  padding-left: 4px;
}

.mat-mdc-menu-submenu-icon {
  --mat-menu-item-icon-size: 5px;
  --mat-menu-item-spacing: 11px;
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  --mat-dialog-content-padding: 0px 14px 28px 21px;
}

.close-button.mat-mdc-icon-button.mat-mdc-button-touch-target {
  --mdc-icon-button-state-layer-size: 42px;
}

.taures-blue-text-button {
  --mdc-text-button-label-text-color: #1F396A;
}

.taures-border-radius { //angular 18 update in commons
  border-radius: 4px;
}

tr-info-box > div.mb-3 { //TODO remove me after commons update and remove tr-info-box component, use messages instead
  border-radius: 4px;
  margin-bottom: 0 !important;
}

.aenderungsauftrag-dialog-content-height .mat-mdc-dialog-content {
  max-height: 75vh;
}
